
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import _ from "lodash";
import helpers from "@/helpers/global";

// let loadingEl: any;

export default defineComponent({
  name: "SetupValues&Traits",
  watch: {
    step(value) {
      if (value) {
        window.scrollTo(0, 0);
      }
    },
    error(error) {
      if (error) {
        ElMessage.error(error);
        setTimeout(() => {
          this.$router.push({ name: "pageNotFound404" });
        }, 3000);
      }
    },
    // loading(value) {
    //   if (value) {
    //     loadingEl = ElLoading.service({
    //       lock: true,
    //       text: "Login..",
    //       background: "#ffffff90",
    //     });
    //   } else {
    //     loadingEl.close();
    //   }
    // },
  },
  data() {
    return {
      step: "get_start", // get_start
      getStartedButtonLoading: false,
      // get_start
      // defining_values_optional
      // defining_your_values
      // idefining_key_traits_optional
      // identifying_key_traits
      // setup_complete
      CUSTOM_FIELDS_ENABLED: true,
      cloneOldSetUp: "",
      addValueModalStatus: false,
      addValueModalLibrary: true,
      valueInputEN: "",
      valueInputTH: "",
      valueOptionalDirty: false,
      valueOptional: [] as any,
      valueDataListOptional: [] as any,
      valuesIncludedOptions: [] as any,
      valuesExcludedOptions: [] as any,
      addTraitsModalStatus: false,
      addTraitsModalLibrary: true,
      traitsInputEN: "",
      traitsInputTH: "",
      isTraitsInit: false,
      traitsOptionalDirty: false,
      traitsOptional: [] as any,
      traitsDataListOptional: [] as any,
      traitsIncludedOptions: [] as any,
      traitsExcludedOptions: [] as any,
      oldSetUpData: [
        {
          id: "111",
          label: "Culture Fit for Jan 2022 Batch",
        },
        {
          id: "222",
          label: "Culture Fit for feb 2022 Batch",
        },
        {
          id: "333",
          label: "Culture Fit for mar 2022 Batch",
        },
      ],
      selectedExistsTemplate: "",
      yourValueDisableNextBTN: true,
      keyTraitsDisableNextBTN: true,
      yourValue: {
        // your value
        achievement: 0,
        adaptability: 0,
        adventure: 0,
        aesthetics: 0,
        // key traits
        articulate: 0,
        authentic: 0,
        balanced: 0,
        careful: 0,
        compassionate: 0,
        competent: 0,
      },
      // trig: avoid lint detection
      answers: [{ id: "", value: 0 }],
      // languages required
      isLangEnEnabled: true as boolean,
      isLangThEnabled: false as boolean,
      aiSetup: null as any,
    };
  },
  computed: {
    companyLogoUrl() {
      const defaultLogoUrl = "";
      const companyLogoUrl = this.$store.getters["user/companyLogoUrl"];
      return companyLogoUrl || defaultLogoUrl;
    },
    error() {
      return this.$store.getters["assessmentSection/error"];
    },
    loading() {
      return this.$store.getters["assessmentSection/loading"];
    },
    valuesAndTraitsLoading() {
      return this.$store.getters["aiAssistedSetup/valuesAndTraitsLoading"];
    },
    valuesAndTraits() {
      const valuesAndTraits =
        this.$store.getters["assessmentSection/valuesAndTraits"];
      if (
        valuesAndTraits &&
        valuesAndTraits.scenarios &&
        valuesAndTraits.scenarios.definingYourValues &&
        valuesAndTraits.scenarios.definingYourValues.questions
      ) {
        // helpers.shuffleArray(
        //   valuesAndTraits.scenarios.definingYourValues.questions
        // );
        valuesAndTraits.scenarios.definingYourValues.questions.sort(
          (a: any, b: any) => {
            const nameA = a.title.toUpperCase(); // ignore upper and lowercase
            const nameB = b.title.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          }
        );
      }
      return valuesAndTraits;
    },
    formId() {
      return this.$store.getters["assessmentTemplate/formId"];
    },
    values() {
      return this.$store.getters["assessmentTemplate/values"];
    },
    existsTemplates() {
      return this.$store.getters["assessmentTemplate/existsTemplates"];
    },
    submitNewValueStatusBTN() {
      if (this.isLangEnEnabled && !this.valueInputEN) return false;
      if (this.isLangThEnabled && !this.valueInputTH) return false;
      return true;
    },
    submitNewTraitsStatusBTN() {
      if (this.isLangEnEnabled && !this.traitsInputEN) return false;
      if (this.isLangThEnabled && !this.traitsInputTH) return false;
      return true;
    },
    isLightColor(): any {
      const user = this.$store.getters["user/user"] || {};
      const color = user.color || "#000";
      return helpers.isLightColor(color);
    },
  },
  created() {
    // clear values with declaration of value
    this.answers = [];
    this.$store.dispatch("user/loadCurrentUser");
  },
  mounted() {
    const formId = this.$router.currentRoute.value.query.id;
    this.checkQuestionId();
    this.load(formId);
    this.$store.dispatch("forceReady", null, { root: true });
  },
  methods: {
    async load(formId: any) {
      await this.$store.dispatch(
        "assessmentTemplate/load",
        { formId: formId, section: "values_and_traits" },
        {
          root: true,
        }
      );

      // try to skip first step and auto set with ai
      if (this.$store.getters["assessmentTemplate/values"]) {
        const values = this.$store.getters["assessmentTemplate/values"];
        const path = "ai_setup.values_and_traits";
        const aiSetup = _.get(values, path);
        if (aiSetup && aiSetup.pending_review) {
          this.aiSetup = aiSetup;
          this.startSetupValues();
        }
      }
    },
    async loadSetupValuesAndTraits() {
      await this.$store.dispatch(
        "assessmentSection/loadSetupValuesAndTraits",
        null,
        {
          root: true,
        }
      );
    },
    async useSelectedExistsTemplate() {
      if (this.selectedExistsTemplate) {
        const selectedExistsTemplate = _.find(this.existsTemplates, {
          _id: this.selectedExistsTemplate,
        });
        if (selectedExistsTemplate) {
          this.getStartedButtonLoading = true;
          const formId = this.$router.currentRoute.value.query.id;
          await this.$store.dispatch(
            "assessmentTemplate/loadFromExistsTemplate",
            {
              formId: formId,
              selectedExistsTemplate: selectedExistsTemplate,
              section: "values_and_traits",
            },
            {
              root: true,
            }
          );
          this.getStartedButtonLoading = false;
        }
      }
    },
    filterMethod(query: string, item: any) {
      return item.initial.toLowerCase().includes(query.toLowerCase());
    },
    // IMPORTANT
    // TODO: managing
    onValuesElTransferChange(
      valueIds: string[],
      direction: string,
      transIds: string[]
    ) {
      if (!_.isArray(transIds)) return;
      if (direction != "left" && direction != "right") return;

      const valuesIncludedOptions = [];
      const valuesExcludedOptions = [];
      for (const i in this.valueDataListOptional) {
        const option = this.valueDataListOptional[i];
        const found = _.indexOf(valueIds, option.key) !== -1;
        if (found) {
          valuesExcludedOptions.push(option);
        } else {
          valuesIncludedOptions.push(option);
        }
      }
      this.valuesIncludedOptions = valuesIncludedOptions;
      this.valuesExcludedOptions = valuesExcludedOptions;
    },
    openValueModal(
      addValueModalStatus: boolean,
      addValueModalLibrary: boolean
    ) {
      this.addValueModalStatus = addValueModalStatus;
      this.addValueModalLibrary = addValueModalLibrary;
    },
    closeValueModal() {
      this.addValueModalStatus = false;
      (this.valueInputEN = ""), (this.valueInputTH = "");
    },
    getInputLabelValue(lang: string, labelEn: any, labelTh: any) {
      if (this.isLangEnEnabled && this.isLangThEnabled) {
        return lang == "en" ? labelEn : labelTh;
      } else {
        if (this.isLangEnEnabled) {
          return labelEn;
        } else if (this.isLangThEnabled) {
          return labelTh;
        }
      }
    },
    submitNewValue() {
      const lang = ((this as any).$i18n.getLocale(0) || "en").toLowerCase();
      if (this.submitNewValueStatusBTN) {
        // const time = "_" + Date.now();
        // const key = this.valueInputEN + time;
        const key = "custom_" + helpers.generateString(20);
        if (this.addValueModalLibrary) {
          // add to included box
          this.valueDataListOptional.push({
            key: key,
            // label: lang == "en" ? this.valueInputEN : this.valueInputTH,
            label: this.getInputLabelValue(
              lang,
              this.valueInputEN,
              this.valueInputTH
            ),
            labelEN: this.valueInputEN,
            labelTH: this.valueInputTH,
          });
        } else {
          // add to excluded box
          this.valueOptional.push(key);
          this.valueDataListOptional.push({
            key: key,
            // label: lang == "en" ? this.valueInputEN : this.valueInputTH,
            label: this.getInputLabelValue(
              lang,
              this.valueInputEN,
              this.valueInputTH
            ),
            labelEN: this.valueInputEN,
            labelTH: this.valueInputTH,
          });
        }
        const valueIds = this.valueOptional;
        const direction = this.addValueModalLibrary ? "left" : "right";
        const transIds = [key];
        this.onValuesElTransferChange(valueIds, direction, transIds);

        this.valueInputEN = "";
        this.valueInputTH = "";
        this.addValueModalStatus = false;
      }
    },
    backToStart() {
      if (this.CUSTOM_FIELDS_ENABLED) {
        this.step = "idefining_key_traits_optional";
      } else {
        this.step = "get_start";
      }
    },
    async startSetupValues() {
      // console.log("startSetupValues()");
      window.scrollTo(0, 0);
      // this.step = "idefining_key_traits_optional";
      this.step = "defining_your_values";

      const aiSetup = this.aiSetup;
      // console.log(aiSetup, "aiSetup");

      // @note used for skip custom step
      if (!this.CUSTOM_FIELDS_ENABLED) {
        this.setupDefiningValues();
        return;
      }

      if (!aiSetup) {
        await this.useSelectedExistsTemplate();
      }

      const isNew = !(this.values.values_and_traits_is_custom || false);
      const lang = ((this as any).$i18n.getLocale(0) || "en").toLowerCase();

      if (Array.isArray(this.values.languages_required)) {
        this.isLangEnEnabled =
          _.indexOf(this.values.languages_required, "English") !== -1;
        this.isLangThEnabled =
          _.indexOf(this.values.languages_required, "Thai") !== -1;
      }

      await this.loadSetupValuesAndTraits();
      const path = "scenarios.definingYourValues.questions";
      const questions = _.get(this.valuesAndTraits, path);
      let direction = "left";
      const transIds = [] as any;

      // console.log(isNew, "isNew");
      // console.log(questions, "questions");

      if (isNew) {
        const includedQuestions = _.map(questions, (obj) => {
          return {
            key: obj.id,
            // @todo keep in mind we must translate all questions
            // label: lang == "en" ? obj.title : obj.title_th,
            label: this.getInputLabelValue(lang, obj.title, obj.title_th),
            labelEN: obj.title,
            labelTH: obj.title_th,
          };
        });
        const excludedQuestions: string[] = [];
        this.valueDataListOptional = includedQuestions;
        this.valueOptional = excludedQuestions;

        if (_.get(this.values, "values_and_traits.defining_your_values")) {
          this.setupDefiningValues();
        }
      } else {
        // console.log(this.values, "this.values");
        const customFields = this.values.values_and_traits_custom_fields;
        // console.log(customFields, "customFields");
        this.valueDataListOptional = _.concat(
          customFields.values_included_options,
          customFields.values_excluded_options || []
        );

        // because option is transformed to snake case
        // console.log(this.valueDataListOptional, "this.valueDataListOptional");
        this.valueDataListOptional = _.map(
          this.valueDataListOptional,
          (option) => {
            return {
              key: option.key,
              // label: lang == "en" ? option.labelEN : option.labelTH,
              label: this.getInputLabelValue(
                lang,
                option.labelEN,
                option.labelTH
              ),
              labelTH: option.labelTH,
              labelEN: option.labelEN,
            };
          }
        );

        const excludedQuestions = _.map(
          customFields.values_excluded_options,
          (option) => {
            return option.key;
          }
        );
        this.valueOptional = excludedQuestions;
        direction = "right";
      }

      if (aiSetup) {
        this.valueOptional = [];
        const questionsMap: any = {};
        for (const i in this.valueDataListOptional) {
          const question = this.valueDataListOptional[i];
          const id = question.key;

          let key = _.snakeCase(question.labelEN);

          // @fixed typo (valid to invalid)
          if (key == "perserverance") {
            key = "perseverance";
          }

          if (!Object.prototype.hasOwnProperty.call(aiSetup.values, key)) {
            this.valueOptional.push(id);
          }

          questionsMap[key] = id;
        }
      }

      const valueIds = this.valueOptional;
      this.onValuesElTransferChange(valueIds, direction, transIds);

      if (aiSetup) {
        const defining_your_values: any = {};
        for (const i in this.valuesIncludedOptions) {
          let label = _.snakeCase(this.valuesIncludedOptions[i].labelEN);
          if (label == "perserverance") {
            label = "perseverance";
          }
          const value = Object.prototype.hasOwnProperty.call(
            aiSetup.values,
            label
          )
            ? aiSetup.values[label]
            : null;
          const key = this.valuesIncludedOptions[i].key;
          defining_your_values[key] = Number(value);
        }
        if (!this.values.values_and_traits) {
          this.values.values_and_traits = {};
        }
        this.values.values_and_traits.defining_your_values =
          defining_your_values;
      }

      if (!isNew) {
        this.beforeSetupDefiningValues();
      }
    },
    // called by next button of defining_values_optional step
    beforeSetupDefiningValues() {
      console.log("beforeSetupDefiningValues()");
      // console.log(this.valueDataListOptional, "this.valueDataListOptional");
      if (this.valueDataListOptional.length > 0) {
        window.scrollTo(0, 0);

        // the easiest way of current dev is keep valuesAndTraits
        // modify only questions of it

        if (this.CUSTOM_FIELDS_ENABLED) {
          // const lang = ((this as any).$i18n.getLocale(0) || "en").toLowerCase();

          // mapping new questions
          const questions = [];
          for (const i in this.valuesIncludedOptions) {
            const option = this.valuesIncludedOptions[i];
            const question = {
              checked: false,
              dirty: false,
              id: option.key,
              name: option.label,
              options: [
                { value: 1, label: "1" },
                { value: 2, label: "2" },
                { value: 3, label: "3" },
                { value: 4, label: "4" },
                { value: 5, label: "5" },
              ],
              order: 0,
              // title: option.labelEN ? option.labelEN : option.labelTH,
              // title_th: option.labelTH ? option.labelTH : option.labelEN,
              title: this.getInputLabelValue(
                "en",
                option.labelEN,
                option.labelTH
              ),
              title_th: this.getInputLabelValue(
                "th",
                option.labelEN,
                option.labelTH
              ),
              type: "choice",
              value: "",
            };
            questions.push(question);
          }
          const valuesAndTraits = this.valuesAndTraits;
          valuesAndTraits.scenarios.definingYourValues.questions = questions;
          this.$store.commit(
            "assessmentSection/valuesAndTraits",
            valuesAndTraits,
            { root: true }
          );
          this.step = "defining_your_values";
        } else {
          // @todo in the future check process is it compatible
          this.setupDefiningValues();
        }

        // EDIT Mode
        // Default only same setting. Dont' know what's first action or back button to edit
        if (
          this.values.values_and_traits &&
          this.values.values_and_traits.defining_your_values
        ) {
          if (!this.valueOptionalDirty) {
            const allIds = _.map(this.valuesIncludedOptions, (option) => {
              return option.key;
            }).sort();
            const selectedValues =
              this.values.values_and_traits.defining_your_values;
            const selectedIds = Object.keys(selectedValues).sort();
            // console.log(allIds, "allIds");
            // console.log(selectedIds, "selectedIds");
            // console.log(
            //   this.valuesIncludedOptions,
            //   "this.valuesIncludedOptions"
            // );
            // console.log(
            //   this.values.values_and_traits.defining_your_values,
            //   "this.values.values_and_traits.defining_your_values"
            // );
            const canDefault = allIds.join(".") == selectedIds.join(".");
            if (canDefault) {
              for (const id in selectedValues) {
                const value =
                  this.values.values_and_traits.defining_your_values[id];
                const path = "definingYourValues";
                const question = { id: id };
                const choice = { value: value };
                this.onClickChoice(path, question, choice);
              }
            }
            this.valueOptionalDirty = true;
          }

          // process to check yourValueDisableNextBTN
          const questions =
            _.get(
              this.valuesAndTraits,
              "scenarios.definingYourValues.questions"
            ) || [];
          let yourValueDisableNextBTN = false;
          for (const i in questions) {
            const question = questions[i];
            const options = questions[i].options || [];
            let found = false;
            for (const j in options) {
              const option = options[j];
              const selected = this.isSelectedChoiceCls(
                "definingYourValues",
                question,
                option
              );
              if (selected === true) {
                found = true;
                break;
              }
            }
            if (!found) {
              yourValueDisableNextBTN = true;
              break;
            }
          }
          this.yourValueDisableNextBTN = yourValueDisableNextBTN;
        }
      }
    },
    async setupDefiningValues() {
      console.log("setupDefiningValues()");
      await this.useSelectedExistsTemplate();
      this.step = "defining_your_values";
      !this.valuesAndTraits && (await this.loadSetupValuesAndTraits());
      if (this.valuesAndTraits) {
        const hasOwnProperty = Object.prototype.hasOwnProperty;
        if (
          this.values &&
          hasOwnProperty.call(this.values, "values_and_traits")
        ) {
          const history = this.values.values_and_traits;
          try {
            for (const key in this.valuesAndTraits.scenarios) {
              const scenario = this.valuesAndTraits.scenarios[key];
              const scenarioKey = _.snakeCase(key);
              for (const i in scenario.questions) {
                const question = scenario.questions[i];
                const questionId = question.id;
                const hasScenario = hasOwnProperty.call(history, scenarioKey);
                if (hasScenario) {
                  const hasValue = hasOwnProperty.call(
                    history[scenarioKey],
                    questionId
                  );
                  if (hasValue) {
                    const value = history[scenarioKey][questionId];
                    const option = _.find(question.options, { value: value });
                    if (!option) {
                      const errorMsg = `Not found option value ${value} on scenario order ${scenario.order}`;
                      console.log(errorMsg);
                      continue;
                    }
                    this.onClickChoice(key, question, option);
                  }
                }
              }
            }
          } catch (e) {
            console.error(e);
          }
        }
      }
    },
    // IMPORTANT
    // TODO: managing
    onTraitsElTransferChange(
      valueIds: string[],
      direction: string,
      transIds: string[]
    ) {
      if (!_.isArray(transIds)) return;
      if (direction != "left" && direction != "right") return;

      // describe include and exclude (not include) values
      const traitsIncludedOptions = [];
      const traitsExcludedOptions = [];
      for (const i in this.traitsDataListOptional) {
        const option = this.traitsDataListOptional[i];
        const found = _.indexOf(valueIds, option.key) !== -1;
        if (found) {
          traitsExcludedOptions.push(option);
        } else {
          traitsIncludedOptions.push(option);
        }
      }

      this.traitsIncludedOptions = traitsIncludedOptions;
      this.traitsExcludedOptions = traitsExcludedOptions;
    },
    submitNewTraits() {
      const lang = ((this as any).$i18n.getLocale(0) || "en").toLowerCase();
      if (this.submitNewTraitsStatusBTN) {
        // const time = "_" + Date.now();
        const key = "custom_" + helpers.generateString(20);
        if (this.addTraitsModalLibrary) {
          // add to included box
          this.traitsDataListOptional.push({
            key: key,
            // label: lang == "en" ? this.traitsInputEN : this.traitsInputTH,
            label: this.getInputLabelValue(
              lang,
              this.traitsInputEN,
              this.traitsInputTH
            ),
            labelEN: this.traitsInputEN,
            labelTH: this.traitsInputTH,
          });
        } else {
          // add to excluded box
          this.traitsOptional.push(key);
          this.traitsDataListOptional.push({
            key: key,
            // label: lang == "en" ? this.traitsInputEN : this.traitsInputTH,
            label: this.getInputLabelValue(
              lang,
              this.traitsInputEN,
              this.traitsInputTH
            ),
            labelEN: this.traitsInputEN,
            labelTH: this.traitsInputTH,
          });
        }

        const valueIds = this.traitsOptional;
        const direction = this.addTraitsModalLibrary ? "left" : "right";
        const transIds = [key];
        this.onTraitsElTransferChange(valueIds, direction, transIds);

        this.traitsInputEN = "";
        this.traitsInputTH = "";
        this.addTraitsModalStatus = false;
      }
    },
    startSetupTraits() {
      // console.log("startSetupTraits()");
      window.scrollTo(0, 0);
      // this.step = "idefining_key_traits_optional";
      this.step = "identifying_key_traits";
      if (!this.CUSTOM_FIELDS_ENABLED) {
        // this.step = "identifying_key_traits";
        return;
      }

      if (this.isTraitsInit) return;

      let direction = "left";
      const transIds = [] as any;

      const isNew = !(this.values.values_and_traits_is_custom || false);
      const lang = ((this as any).$i18n.getLocale(0) || "en").toLowerCase();
      if (isNew) {
        const path = "scenarios.identifyingKeyTraits.questions";
        const questions = _.get(this.valuesAndTraits, path);
        const includedQuestions = _.map(questions, (obj) => {
          return {
            key: obj.id,
            // @todo keep in mind we must translate all questions
            // label: lang == "en" ? obj.title : obj.title_th,
            label: this.getInputLabelValue(lang, obj.title, obj.title_th),
            labelEN: obj.title,
            labelTH: obj.title_th,
          };
        });
        const excludedQuestions: string[] = [];
        this.traitsDataListOptional = includedQuestions;
        this.traitsOptional = excludedQuestions;
      } else {
        const customFields = this.values.values_and_traits_custom_fields;
        this.traitsDataListOptional = _.concat(
          customFields.traits_included_options,
          customFields.traits_excluded_options || []
        );

        // because option is transformed to snake case
        this.traitsDataListOptional = _.map(
          this.traitsDataListOptional,
          (option) => {
            return {
              key: option.key,
              // label: lang == "en" ? option.labelEN : option.labelTH,
              label: this.getInputLabelValue(
                lang,
                option.labelEN,
                option.labelTH
              ),
              labelTH: option.labelTH,
              labelEN: option.labelEN,
            };
          }
        );

        const excludedQuestions = _.map(
          customFields.traits_excluded_options,
          (option) => {
            return option.key;
          }
        );
        this.traitsOptional = excludedQuestions;
        direction = "right";
      }

      const aiSetup = this.aiSetup;
      if (aiSetup) {
        this.traitsOptional = [];
        const questionsMap: any = {};
        for (const i in this.traitsDataListOptional) {
          const question = this.traitsDataListOptional[i];
          const id = question.key;
          const key = _.snakeCase(question.labelEN);
          if (!Object.prototype.hasOwnProperty.call(aiSetup.traits, key)) {
            this.traitsOptional.push(id);
          }
          questionsMap[key] = id;
        }
      }

      const valueIds = this.traitsOptional;
      this.onTraitsElTransferChange(valueIds, direction, transIds);

      if (!isNew) {
        this.beforeSetupIdefiningKeyTraits();
      }

      // console.log(
      //   this.values.values_and_traits,
      //   "this.values.values_and_traits"
      // );
      if (aiSetup) {
        const identifying_key_traits: any = {};
        for (const i in this.traitsIncludedOptions) {
          const label = _.snakeCase(this.traitsIncludedOptions[i].labelEN);
          const value = Object.prototype.hasOwnProperty.call(
            aiSetup.traits,
            label
          )
            ? aiSetup.traits[label]
            : null;
          const key = this.traitsIncludedOptions[i].key;
          // console.log(label, value, key);
          identifying_key_traits[key] = Number(value);
        }
        this.values.values_and_traits.identifying_key_traits =
          identifying_key_traits;
      }

      this.isTraitsInit = true;
    },
    beforeSetupIdefiningKeyTraits() {
      // console.log("beforeSetupIdefiningKeyTraits()");
      if (this.traitsIncludedOptions.length > 0) {
        window.scrollTo(0, 0);

        if (this.CUSTOM_FIELDS_ENABLED) {
          // mapping new questions
          const questions = [];
          for (const i in this.traitsIncludedOptions) {
            const option = this.traitsIncludedOptions[i];
            const question = {
              checked: false,
              dirty: false,
              id: option.key,
              name: option.label,
              options: [
                { value: 1, label: "1" },
                { value: 2, label: "2" },
                { value: 3, label: "3" },
                { value: 4, label: "4" },
                { value: 5, label: "5" },
              ],
              order: 0,
              // title: option.labelEN,
              // title_th: option.labelTH,
              title: this.getInputLabelValue(
                "en",
                option.labelEN,
                option.labelTH
              ),
              title_th: this.getInputLabelValue(
                "th",
                option.labelEN,
                option.labelTH
              ),
              type: "choice",
              value: "",
            };
            questions.push(question);
          }
          const valuesAndTraits = this.valuesAndTraits;
          valuesAndTraits.scenarios.identifyingKeyTraits.questions = questions;
          this.$store.commit(
            "assessmentSection/valuesAndTraits",
            valuesAndTraits,
            { root: true }
          );
        }

        // EDIT Mode
        // Default only same setting. Dont' know what's first action or back button to edit
        if (
          this.values.values_and_traits &&
          this.values.values_and_traits.identifying_key_traits
        ) {
          if (!this.traitsOptionalDirty) {
            const allIds = _.map(this.traitsIncludedOptions, (option) => {
              return option.key;
            }).sort();
            const selectedValues =
              this.values.values_and_traits.identifying_key_traits;
            const selectedIds = Object.keys(selectedValues).sort();
            const canDefault = allIds.join(".") == selectedIds.join(".");
            if (canDefault) {
              for (const id in selectedValues) {
                const value =
                  this.values.values_and_traits.identifying_key_traits[id];
                const path = "identifyingKeyTraits";
                const question = { id: id };
                const choice = { value: value };
                this.onClickChoice(path, question, choice);
              }
            }
            this.traitsOptionalDirty = true;
          }

          // process to check keyTraitsDisableNextBTN
          const questions =
            _.get(
              this.valuesAndTraits,
              "scenarios.identifyingKeyTraits.questions"
            ) || [];
          let keyTraitsDisableNextBTN = false;
          for (const i in questions) {
            const question = questions[i];
            const options = questions[i].options || [];
            let found = false;
            for (const j in options) {
              const option = options[j];
              const selected = this.isSelectedChoiceCls(
                "identifyingKeyTraits",
                question,
                option
              );
              if (selected === true) {
                found = true;
                break;
              }
            }
            if (!found) {
              keyTraitsDisableNextBTN = true;
              break;
            }
          }
          this.keyTraitsDisableNextBTN = keyTraitsDisableNextBTN;
        }

        this.step = "identifying_key_traits";
      }
    },
    checkQuestionId() {
      const id = this.$router.currentRoute.value.query.id;
      if (!id) {
        ElMessage.error((this as any).$t("popup.message.message_16e"));
        setTimeout(() => {
          this.$router.push({ name: "pageNotFound404" });
        }, 1000);
      }
    },
    closeTab() {
      window.close();
    },
    sortTraitsByValues() {
      // @see https://trello.com/c/c3OAjDA8/1339-assessment-as-a-company-i-want-to-be-able-to-configure-my-values-traits-from-a-preset-list-so-that-i-can-easily-identify-items-t
      const scenarios = this.valuesAndTraits.scenarios;
      const valuesQuestions = scenarios.definingYourValues.questions;

      // because we can press previos button for edit values
      // so answers have total more than real total
      const valuesQuestionIds: string[] = [];
      for (const i in valuesQuestions) {
        valuesQuestionIds.push(valuesQuestions[i].id);
      }
      const answersMap: any = {};
      for (const i in this.answers) {
        answersMap[this.answers[i].id] = this.answers[i].value;
      }

      // can i easily sort related traits here?
      // simple is the best! sort here
      // scenarios.identifyingKeyTraits.questions.reverse();
      scenarios.identifyingKeyTraits.questions = _.sortBy(
        scenarios.identifyingKeyTraits.questions,
        [
          (question) => {
            if (question.related_to) {
              if (_.indexOf(valuesQuestionIds, question.related_to) !== -1) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    answersMap,
                    question.related_to
                  )
                ) {
                  const value = answersMap[question.related_to];
                  question.sortValue = value;
                  return value;
                }
              }
            } else {
              console.log("Not found related_to", question);
            }
            return -1;
          },
        ]
      ).reverse();
    },
    yourValueDisableNext() {
      if (!this.yourValueDisableNextBTN) {
        this.sortTraitsByValues();
        window.scrollTo(0, 0);
        // this.step = "identifying_key_traits";
        this.startSetupTraits();
      }
    },
    keyTraitsDisableNext() {
      this.$store.commit("assessmentSection/loading", true);
      let sectionAnswersDto: any = {
        _id: this.$router.currentRoute.value.query.id,
        section: this.valuesAndTraits,
        answers: this.answers,
        callback: () => {
          if (this.aiSetup) {
            this.$store.dispatch(
              "aiAssistedSetup/unsetup",
              "values_and_traits",
              {
                root: true,
              }
            );
          }
          this.$store.commit("assessmentSection/loading", false);
          this.step = "setup_complete";
        },
      };

      if (this.CUSTOM_FIELDS_ENABLED) {
        sectionAnswersDto.valuesAndTraitsIsCustom = true;
        sectionAnswersDto.valuesAndTraitsCustomFields = {
          valuesIncludedOptions: this.valuesIncludedOptions,
          valuesExcludedOptions: this.valuesExcludedOptions,
          traitsIncludedOptions: this.traitsIncludedOptions,
          traitsExcludedOptions: this.traitsExcludedOptions,
        };
      }

      this.$store.dispatch(
        "assessmentTemplate/saveSectionAnswers",
        sectionAnswersDto,
        {
          root: true,
        }
      );
    },
    barWidht(progress: number, candidates: number) {
      const pergressPercentage = (progress / candidates) * 100;
      return `width: ${pergressPercentage.toFixed(1)}%`;
    },
    fontColor(progress: number, candidates: number) {
      const pergressPercentage = (progress / candidates) * 100;
      if (+pergressPercentage.toFixed(1) > 50) {
        return true;
      }
      return false;
    },
    onClickChoice(path: string, question: any, choice: any) {
      const answer = { id: String(question.id), value: Number(choice.value) };
      const index = _.findIndex(this.answers, { id: answer.id });
      if (index === -1) {
        this.answers.push(answer);
      } else {
        this.answers[index].value = answer.value;
      }

      let questionIds = [];
      try {
        questionIds = this.valuesAndTraits.scenarios[path].questions.map(
          (q: any) => {
            return q.id;
          }
        );
      } catch (e) {
        console.error(e);
      }

      // handle state of "yourValueDisableNextBTN"
      const size = questionIds.length;
      let count = 0;
      let done = false;
      for (const index in this.answers) {
        if (_.indexOf(questionIds, this.answers[index].id) !== -1) {
          count++;
        }
        if (count == size) {
          done = true;
          break;
        }
      }
      if (path == "definingYourValues") {
        this.yourValueDisableNextBTN = !done;
      } else if (path == "identifyingKeyTraits") {
        this.keyTraitsDisableNextBTN = !done;
      }
    },
    isSelectedChoiceCls(path: string, question: any, choice: any) {
      const index = _.findIndex(this.answers, {
        id: String(question.id),
        value: Number(choice.value),
      });
      return index !== -1;
    },
    // @todo I'll refactor this after launch beta version (common function)
    lang(obj: any, attr: string) {
      return helpers.objectLang(obj, attr);
    },
  },
});
